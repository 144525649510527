<template>
  <div class="log-list">
    <Empty photo="3" label="暂无物流信息" v-if="list.length == 0"/>
    <router-link :to="'/logistics/detail/' + item.order_sn" class="log-card" v-for="(item,index) in list" :key="index">
      <div class="title">
        <div class="icon">
          <img src="~@/assets/images/icon/icon_logistics.svg" alt="">
        </div>
        <h5>物流信息</h5>
      </div>
      <div class="num">快递单号：{{ item.order_sn }}</div>
      <div class="btn flex-center">查看</div>
    </router-link>
  </div>
</template>

<script>
  import { getLogisticsList } from '@/api/order'
  import { Toast } from 'vant'
  import Empty from '@/components/empty'

  export default {
    components: {
      Empty
    },
    data() {
      return {
        list: []
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getLogisticsList().then(res => {
          this.list = res.data.list
          toast.clear()
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>